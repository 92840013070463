<template>
    <div class="photo-banner">
        <div class="aspect-w-16 aspect-h-6">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import simpleParallax from 'simple-parallax-js';
import { onMounted } from 'vue';

onMounted(() => {
    new simpleParallax(document.querySelector('.photo-banner img'));
});
</script>
