<template>
    <h1 class="h1 text-white w-full max-w-2xl min-h-24 md:min-h-52 mx-auto lg:mx-0">
        <slot></slot>

        <div ref="viewport" class="relative" :style="{height: viewportHeight + 'px'}">
            <div v-for="(headline, idx) in headlines" class="absolute w-full select-none text-blue-500 opacity-0 aria-selected:opacity-100 transition-opacity duration-1000" :aria-selected="current == idx" ref="headlineEls">
                {{ headline }}
            </div>
        </div>

        
    </h1>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps({ headlines: { type: Array, required: true }});

const viewport = ref(null);
const headlineEls = ref(null);
const viewportHeight = ref(120);
const current = ref(0);

onMounted(() => { 
    setViewportHeight()

    setInterval(() => update(), 4000);
});

function setViewportHeight() {
    let height = 0;

    headlineEls.value.forEach(el => {
        if(el.offsetHeight > height) {
            height = el.offsetHeight;
        }
    });

    viewportHeight.value = height;
}

function update() {
    const max = props.headlines.length;

    if(current.value == (max - 1)) {
        current.value = 0;
    } else {
        current.value++;
    }
}


</script>
