<template>
    <div class="gallery-carousel relative z-20 w-full h-full !overflow-visible">
        <div class="glide h-full">
            <div class="glide__track h-full" data-glide-el="track">
                <div class="glide__slides h-full">
                    <div v-for="image in images" class="glide__slide">
                        <img :src="image.image.preview" :alt="image.image.alt" class="block w-full h-full object-cover" />
                    </div>
                </div>
            </div>
            <div class="glide__bullets absolute flex -bottom-9 gap-x-1.5 z-30" data-glide-el="controls[nav]">
                <button v-for="(image, idx) in images" :data-glide-dir="'=' + idx" class="glide__bullet w-5 h-5 bg-blue-100 hover:bg-blue-500/50 transition-colors"></button>
            </div>
        </div>
    </div>
</template>

<script setup>
import Glide, { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';
import { onMounted, ref } from 'vue';

const glideOpts = {
    perView: 1,
};

const props = defineProps({ images: { type: Array, require: true } });

const glider = ref(null);

onMounted(() => {
    glider.value = new Glide('.gallery-carousel .glide', glideOpts).mount({ Controls, Breakpoints });
});
</script>

<style>
.gallery-carousel .glide__bullet--active {
    @apply bg-blue-500;
}
</style>
