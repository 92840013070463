<template>
    <header class="site-header" :class="classList">
        <div class="container-xl px-0">
            <div class="site-header-inner">
                <a href="/" class="site-logo">
                    <InlineSvg src="/app/themes/packard/images/logo.svg"></InlineSvg>
                </a>

                <button type="button" @click.prevent="toggleNav" class="site-nav-toggle">
                    <span class="absolute -inset-0.5" />
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon v-if="!open" class="block h-10 w-10" aria-hidden="true" />
                    <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                </button>

                <nav class="site-nav">
                    <slot></slot>
                </nav>
            </div>
        </div>
    </header>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';

const open = ref(false);
const threshold = ref(120);
const scrollPos = ref(window.scrollTop);
const scrollDir = ref('down');

const belowThreshold = computed(() => scrollPos.value > threshold.value);

const classList = computed(() => {
    const list = [];
    const headerHeight = document.querySelector('.site-header') ? document.querySelector('.site-header').offsetHeight : 100;

    if (open.value) {
        list.push('open');
    }

    if (!belowThreshold.value) {
        list.push('at-top');
    }

    if (scrollPos.value < headerHeight || scrollDir.value == 'up') {
        list.push('translate-y-0');
    } else {
        list.push('-translate-y-full');
    }

    return list;
});

onMounted(() => {
    document.querySelector('.site-header').offsetHeight;

    updateThreshold();
    updateScroll();

    window.addEventListener('scroll', (e) => updateScroll());
});

function updateScroll() {
    const newScrollPos = window.scrollY;

    scrollDir.value = newScrollPos < scrollPos.value ? 'up' : 'down';
    scrollPos.value = newScrollPos;
}

function updateThreshold() {
    threshold.value = document.querySelector('.site-header').offsetHeight;
}

function toggleNav() {
    open.value = !open.value;
    document.body.classList.toggle('height-screen')
    document.body.classList.toggle('overflow-hidden')
}
</script>

<style>
.site-header {
    @apply fixed top-0 left-0 w-full z-50 bg-white transition-all;
}

.site-header-inner {
    @apply w-full h-20 md:h-24 bg-white px-5 lg:px-10 flex justify-between items-center transition-all;
}

.site-nav {
    @apply fixed top-20 left-0 bg-blue-950 w-full flex flex-col h-[calc(100vh-5rem)] translate-x-full transition-all duration-300;
    @apply lg:static lg:bg-blue-950/0 lg:translate-x-0 lg:flex-row lg:justify-between lg:items-center lg:h-32 lg:ml-56 lg:max-w-[862px] lg:border-b lg:border-transparent;
}

.site-header.open .site-nav {
    @apply translate-x-0;
}

.site-nav div {
    @apply py-3 border-t border-white/10 lg:border-0 lg:h-12 flex items-center justify-center;
}

.site-nav a {
    @apply font-headline block text-3xl md:text-4xl py-3 md:py-5 lg:text-base uppercase leading-snug text-white lg:text-gray-800 transition-colors hover:text-blue-700 select-none text-center;
}

.site-header.at-top .site-header-inner {
    @apply bg-transparent lg:h-32;
}

.site-header.open .site-header-inner {
    @apply !bg-white;
}

.site-header.at-top {
    @apply bg-white/0 border-blue-500 h-20 md:h-32;
}

.site-header.at-top .site-nav {
    @apply border-white;
}

.site-logo svg {
    @apply text-[#051173] block h-11 md:h-14 w-auto;
}

.site-logo path {
    @apply transition-all;
}

.site-logo .logo-color-secondary {
    @apply fill-gray-500;
}

.site-logo .logo-color-tertiary {
    @apply fill-blue-700;
}

.site-header.at-top .site-logo svg {
    @apply text-white;
}

.site-header.at-top .site-logo .logo-color-secondary,
.site-header.at-top .site-logo .logo-color-tertiary {
    @apply fill-white;
}

.site-header.open .site-logo .logo-color-secondary {
    @apply fill-gray-500;
}

.site-header.open .site-logo .logo-color-tertiary {
    @apply fill-blue-500;
}

.site-header.open .site-logo svg {
    @apply text-[#051173];
}

.site-header.at-top .site-nav a {
    @apply text-white hover:text-blue-500;
}

.site-nav-item-sep {
    @apply grow transition-all;
    @apply lg:grow-0 lg:w-px lg:h-12 lg:bg-gray-800;
}

.site-header.at-top .site-nav-item-sep {
    @apply lg:bg-white;
}

.site-nav-toggle {
    @apply -m-2.5 lg:hidden inline-flex items-center justify-center rounded-md p-2.5 text-blue-900 transition-colors hover:text-blue-500;
}

.site-header.at-top .site-nav-toggle {
    @apply text-white;
}

.site-header.open .site-nav-toggle {
    @apply text-[#051173];
}
</style>
