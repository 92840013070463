<template>
    <div class="partnerships-carousel">
        <div class="container-lg">
            <div class="flex flex-col xl:flex-row gap-y-12 md:gap-y-0 gap-x-12 justify-between items-center">
                <h4 v-if="headline" class="h3 text-blue-500">{{ headline }}</h4>
                <CarouselNav :items="items" :active="active" @update:active="goto($event)" class="hidden xl:flex" />
            </div>
        </div>

        <div class="carousel-wrapper">
            <div class="container-xl mt-10 md:mt-16 lg:mt-24">
                <div class="glide" :class="{ 'glide-is-first': isFirst, 'glide-is-last': isLast }">
                    <div class="glide__track" data-glide-el="track">
                        <div class="glide__slides flex items-start md:items-stretch">
                            <div v-for="(item, idx) in items" class="glide__slide w-full mx-auto max-w-[1440px] relative !h-auto">
                                <a :href="item.permalink" class="block group transition-colors px-0 md:px-4 lg:px-6 relative z-20 cursor-pointer h-full">
                                    <div class="flex flex-col-reverse xl:flex-row bg-white h-full">
                                        <div class="basis-full xl:basis-1/2 relative md:grow">
                                            <div class="py-6 pb-8 md:py-12 md:pb-12 px-10 3xl:py-16 3xl:pb-16 lg:px-14 md:flex md:flex-col justify-between md:h-full">
                                                <div>
                                                    <h3 class="h4 text-blue-500 group-hover:text-blue-800 transition-colors mb-8 lg:mb-10 3xl:mb-16">{{ item.title }}</h3>
                                                    <div v-html="item.description.length ? item.description : item.intro" class="xl:max-w-lg"></div>
                                                </div>

                                                <div class="flex flex-wrap 3xl:flex-nowrap gap-y-8 3xl:gap-y-12 gap-x-12 mt-12 lg:mt-16 3xl:mt-20 relative z-20">
                                                    <div>
                                                        <h6 class="h6 with-bullet text-blue-500 mb-3 md:mb-5">In Collaboration With</h6>
                                                        <div class="ml-9 flex flex-wrap gap-x-4 gap-y-2">
                                                            <div v-for="partner in item.partners" class="h-12 2xl:h-14 2xl:max-h-14">
                                                                <img :src="partner.image.preview" class="block h-full w-full object-contain object-left" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="item.details.length">
                                                        <h6 class="h6 with-bullet text-blue-500 mb-3 md:mb-5 text-nowrap">Product Details</h6>

                                                        <ul class="list-square">
                                                            <li v-for="detail in item.details" class="ml-14 mt-3 first:mt-0">{{ detail }}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="hidden xl:block absolute z-10 bg-white w-16 h-16 md:w-24 md:h-24 left-1/2 md:left-full top-full md:top-1/2 -translate-x-1/2 -translate-y-1/2 rotate-45"></div>
                                        </div>

                                        <div class="basis-full xl:basis-1/2">
                                            <div class="relative w-full xl:h-full">
                                                <div class="w-full pb-[50%]">
                                                    <img :src="item.image.preview" class="absolute block w-full h-full object-cover" />
                                                </div>
                                                <button
                                                    type="button"
                                                    class="button hidden xl:flex absolute bottom-1/2 right-1/2 md:bottom-10 md:right-8 translate-y-1/2 translate-x-1/2 md:translate-y-0 md:translate-x-0 bg-white/75 md:bg-white/50 group-hover:bg-white group-hover:text-blue-500"
                                                >
                                                    <span class="mr-10">Learn More</span>
                                                    <button-arrow></button-arrow>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CarouselNav :items="items" :active="active" @update:active="goto($event)" class="flex xl:hidden mt-8 md:mt-12 mx-auto justify-center" />
    </div>
</template>

<script setup>
import Glide, { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';
import { onMounted, ref, computed } from 'vue';
import CarouselNav from './CarouselNav.vue';
import { AutoHeight } from '../../modules/glide-autoheight';

const props = defineProps({
    items: { type: Array, default: () => [] },
    headline: { type: String, default: null },
});

const glider = ref(null);
const active = ref(0);

const glideOpts = {
    peek: 200,
    breakpoints: {
        1920: { peek: 156 },
        1600: { peek: 116 },
        1440: { peek: 92 },
        1196: { peek: 64 },
        768: {
            // tailwind md
            peek: 0,
            gap: 0,
            autoheight: true,
        },
    },
};

onMounted(() => {
    glider.value = new Glide('.partnerships-carousel .glide', glideOpts).mount({ Controls, Breakpoints, AutoHeight });

    glider.value.on(['run'], () => (active.value = glider.value.index));
});

const isFirst = computed(() => {
    return !active.value;
});

const isLast = computed(() => {
    return active.value >= props.items.length - 1;
});

function goto(pattern) {
    glider.value.go(pattern);
}
</script>

<style>
.partnerships-carousel .glide {
    @apply relative;
}

.partnerships-carousel .glide::before,
.partnerships-carousel .glide::after {
    @apply content-[''] hidden 2xl:block absolute top-1/2 -translate-y-1/2 w-px h-[110%] bg-blue-800 transition-all;
}

.partnerships-carousel .glide::before {
    @apply left-0;
}

.partnerships-carousel .glide.glide-is-first::before {
    @apply opacity-0;
}

.partnerships-carousel .glide.glide-is-last::after {
    @apply opacity-0;
}

.partnerships-carousel .glide::after {
    @apply left-full;
}

.partnerships-carousel .glide__slide {
    @apply opacity-25 transition-opacity;
}
.partnerships-carousel .glide__slide--active {
    @apply opacity-100;
}
</style>
