<template>
    <div class="container-xl px-0">
        <MapGraphic id="sales-map" class="block w-full h-auto" />
    </div>
</template>

<script setup>
import { onMounted } from 'vue';

import MapGraphic from '../../../images/map.svg';

const props = defineProps({ countries: { type: Array, required: true } });

onMounted(() => {
    props.countries.forEach((country) => {
        const el = document.querySelector('#sales-map #' + country);

        if (el) {
            el.classList.add('country-active');
        }
    });
});
</script>

<style>
#sales-map {
    @apply fill-gray-100;
}

#sales-map .country-active {
    @apply fill-blue-100 hover:fill-blue-500 transition-all duration-300;
}
</style>
