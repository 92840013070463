import '../styles/packard.css';

import { createApp } from 'vue';
import ButtonArrow from './components/Ui/ButtonArrow.vue';
import GalleryCarousel from './components/Gallery/Carousel.vue';
import HomeMap from './components/Home/Map.vue';
import HomeMastheadHeadline from './components/Home/MastheadHeadline.vue';
import InlineSvg from 'vue-inline-svg';
import PartnershipsCarousel from './components/Partnerships/Carousel.vue';
import PhotoBanner from './components/Ui/PhotoBanner.vue';
import ProductHighlights from './components/Product/ProductHighlights.vue';
import SiteNavbar from './components/Site/Navbar.vue';

const app = createApp();

app.component('button-arrow', ButtonArrow);
app.component('gallery-carousel', GalleryCarousel);
app.component('home-map', HomeMap);
app.component('home-masthead-headline', HomeMastheadHeadline);
app.component('inline-svg', InlineSvg);
app.component('partnerships-carousel', PartnershipsCarousel);
app.component('photo-banner', PhotoBanner)
app.component('product-highlights', ProductHighlights);
app.component('site-navbar', SiteNavbar);

app.mount('#app');

