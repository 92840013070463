<template>
    <div class="items-center gap-x-6">
        <button type="button" class="carousel-arrow" @click.prevent="emit('update:active', '<')" :disabled="isFirst"><button-arrow class="rotate-180"></button-arrow></button>
        <div class="flex gap-x-3 items-center">
            <button v-for="(item, idx) in items" type="button" class="carousel-dot" @click.prevent="emit('update:active', '=' + idx)" :aria-selected="active === idx"></button>
        </div>
        <button type="button" class="carousel-arrow" @click.prevent="emit('update:active', '>')" :disabled="isLast"><button-arrow></button-arrow></button>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    items: { type: Array, required: true },
    active: { type: Number, required: true },
});

const emit = defineEmits(['update:active']);

const isFirst = computed(() => !props.active);
const isLast = computed(() => props.active >= props.items.length - 1);
</script>

<style>
.carousel-arrow {
    @apply w-12 h-12 lg:w-16 lg:h-16 border text-blue-500 border-blue-500 rounded-full flex items-center justify-center bg-blue-900 enabled:hover:bg-blue-500/25 enabled:hover:text-white transition-colors disabled:opacity-40;
}

.carousel-dot {
    @apply w-6 h-6 border border-blue-500 rounded-full bg-blue-900 aria-selected:bg-blue-500/40 transition-colors hover:bg-blue-500/25;
}
</style>
