<template>
    <button :style="'top:' + model.y + '%;left:' + model.x + '%'"></button>
</template>

<script setup>
const model = defineModel({ type: Object, required: true });
</script>

<style scoped>
button {
    @apply absolute bg-white/30 border border-blue-500 rounded-full w-[52px] h-[52px] -translate-x-1/2 -translate-y-1/2 transition-all;
}

button::before,
button::after {
    @apply content-[''] absolute top-1/2 left-1/2 rounded-full -translate-x-1/2 -translate-y-1/2 transition-all;
}

button::before {
    @apply bg-white border border-blue-500  w-[28px] h-[28px];
}

button::after {
    @apply bg-[#2A5082] w-[7px] h-[7px];
}

button:hover {
    @apply border-4;
}

button:hover::before {
    @apply border-4 bg-white/30;
}

button[aria-selected='true'] {
    @apply bg-blue-500/30 border-4 w-[58px] h-[58px] border-blue-900;
}

button[aria-selected='true']::before {
    @apply bg-blue-500/60 border-4 w-[32px] h-[32px];
}

button[aria-selected='true']::after {
    @apply bg-white;
}
</style>
