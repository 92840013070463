<template>
    <div class="grid grid-cols-2 items-center gap-x-12">
        <div class="basis-1/2 flex justify-end items-start pr-24">
            <div class="relative">
                <img :src="model.image.preview" :alt="model.headline" class="block w-full max-w-lg" />
                <ProductHighlightsMarker v-for="(item, idx) in model.items" v-model="model.items[idx]" :aria-selected="idx == active" @click="active = idx" />
            </div>
        </div>

        <div class="basis-1/2">
            <div class="glide-product-highlights">
                <div class="bg-white py-16 px-12 w-full relative z-20 border-l border-blue-500">
                    <div class="glide">
                        <div class="glide__track" data-glide-el="track">
                            <div class="glide__slides">
                                <div v-for="(item, idx) in model.items" class="glide__slide">
                                    <h4 class="h6 text-blue-500 mb-6">{{ item.headline }}</h4>
                                    <p>{{ item.content }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import ProductHighlightsMarker from './ProductHighlightsMarker.vue';

import Glide, { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

const model = defineModel('meta', { type: Object, required: true });

const active = ref(0);
const glider = ref(null);

onMounted(() => {
    glider.value = new Glide('.glide-product-highlights .glide').mount({ Controls, Breakpoints });
});

watch(active, (value) => {
    glider.value.go('=' + value);
})
</script>

<style scoped>
.glide__slides {
    transform: translate3d(0, 0, 0) !important;
}

.glide__slide {
    position: absolute;
    top: 0;
    left: 0;
    order: 2;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}

.glide__slide.glide__slide--active {
    position: relative;
    order: 1;
    opacity: 1;
    z-index: 1;
}

.glide-product-highlights {
 @apply relative;
}

.glide-product-highlights::before {
    @apply absolute content-[''] w-[115%] h-[115%] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2;
    background: radial-gradient(50% 50% at 50% 50%, #4EBEFA 26%, rgba(239, 239, 239, 0) 100%);

}
/* Rectangle 242 */

/* position: absolute;
width: 1170.85px;
height: 744.6px;
left: 732px;
top: 2883.72px;

background: radial-gradient(50% 50% at 50% 50%, #4EBEFA 26%, rgba(239, 239, 239, 0) 100%);
transform: matrix(1, 0, 0, -1, 0, 0);
 */
</style>
